import React, { useEffect, useState } from "react";
import { FaClock } from "react-icons/fa";
import { MdDirections } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./index.css";

const url = process.env.REACT_APP_BASE_URL;

const NearByMasjid = () => {
  const [mosques, setMosques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMosques = async (latitude, longitude) => {
      try {
        console.log(latitude, longitude, "call");
        const response = await fetch(`${url}getnearbymasjeeds`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userlatitude: latitude,
            userlongitude: longitude,
          }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("mosques list", data.results);
        setMosques(data.results);
      } catch (err) {
        setError("Failed to fetch mosques");
      } finally {
        setLoading(false);
      }
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchMosques(latitude, longitude);
          },
          (err) => {
            setError("Failed to get location");
            setLoading(false);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser");
        setLoading(false);
      }
    };

    getLocation();
  }, []);

  const handleViewTimings = (mosqueId) => {
    navigate(`/singlemasjid/${mosqueId}`);
  };

  console.log("mosques", mosques);

  return (
    <div className="near-by-masjeed-main-container">
      <h1 className="near-by-masjeed-main-container-title">Near by Masjids</h1>
      <div className="near-by-masjeed-cards-container">
        {mosques.length > 0 ? (
          mosques.map((mosque) => (
            <div className="masjid-card" key={mosque.id}>
              <div className="masjid-details">
                <h2 className="masjid-details-title">{mosque.masjeedname}</h2>
                <p className="masjid-details-distance">{mosque.distance}</p>
                <p className="masjid-details-address">
                  Address: {mosque.address}
                </p>
                <p className="masjid-details-address">
                  {mosque.city}, {mosque.state}, {mosque.postalcode}
                </p>
                <div className="masjid-actions">
                  <button
                    className="timings-button"
                    onClick={() => handleViewTimings(mosque.id)}
                  >
                    <FaClock />
                    Salah Timings
                  </button>
                  <button
                    className="directions-button"
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/dir/?api=1&destination=${mosque.latitude},${mosque.longitude}`,
                        "_blank"
                      )
                    }
                  >
                    <MdDirections />
                    Direction
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No mosques found</div>
        )}
      </div>
    </div>
  );
};

export default NearByMasjid;
