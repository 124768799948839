import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import "./index.css";
import masjeedLogo from "../utils/merimasjidlogo.png";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();

  useEffect(() => {}, [location]);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar-main-container">
      <div className="navbar-sub-container">
        <div className="navbar-company-logo-container">
          <Link to="/">
            <img src={masjeedLogo} alt="logo" className="navbar-company-logo" />
          </Link>
          <Link to="/" className="no-decoration">
            <h1 className="navbar-company-logo-container-title">Meri Masjid</h1>
          </Link>
          {location.pathname !== "/selectmasjid" && (
            <Link to="/selectmasjid" className="no-decoration">
              <button className="navbar-search-button">
                <FaSearch />
                Search Masjid
              </button>
            </Link>
          )}
        </div>

        {showNavbar ? (
          <div className="navbar-menu-icon" onClick={handleShowNavbar}>
            <RxCross2 style={{ color: "#fff", fontSize: "24px" }} />
          </div>
        ) : (
          <div className="navbar-menu-icon" onClick={handleShowNavbar}>
            <RxHamburgerMenu style={{ color: "#fff", fontSize: "24px" }} />
          </div>
        )}
        <div className={`nav-elements ${showNavbar && "nav-active"}`}>
          <ul>
            {location.pathname !== "/register" && (
              <li>
                <Link to="/register">Register Your Masjid</Link>
              </li>
            )}
            {location.pathname !== "/login" && (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
