import Navbar from "../../Navbar";
import SelectMasjid from "../../SelectMasjid";
import Banner from "../Banner";
import Features from "../Features";
import Footer from "../Footer";
import HomeImage from "../HomeImage";
import HomeInfo from "../HomeInfo";
import HomeInformation from "../HomeInformation";
import Testimonial from "../Testimonial";
import TrustedBy from "../TrustedBy";
import MapComponent from "../../MapPage/map";
import NearByMasjid from "../../NearByMasjid/index";
import "./index.css";
import MosqueContext from "../../../context/context";
import { useContext } from "react";

const MosqueDetails = () => {
  const { mosqueData, setMosqueData } = useContext(MosqueContext);

  if (!mosqueData) {
    return null;
  }

  const handleCloseClick = () => {
    setMosqueData(null); // Clear the mosque data to show NearByMasjid
  };

  const handleDirectionsClick = () => {
    if (mosqueData.location) {
      const { lat, lng } = mosqueData.location;
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
        "_blank"
      );
    } else {
      console.error("Location is not available.");
    }
  };

  return (
    <div className="mosque-details-container">
      <button className="close-button" onClick={handleCloseClick}>
        &times;
      </button>
      <h2>{mosqueData.name}</h2>
      <p>{mosqueData.address}</p>
      {mosqueData.photos && mosqueData.photos.length > 0 && (
        <div className="mosque-photos">
          {mosqueData.photos.map((url, index) => (
            <img key={index} src={url} alt={`Mosque ${index}`} />
          ))}
        </div>
      )}
      {mosqueData.location && (
        <button onClick={handleDirectionsClick}>Get Directions</button>
      )}
    </div>
  );
};

function HomePageMain() {
  const { mosqueData } = useContext(MosqueContext);

  return (
    <>
      <Navbar />
      <div className="home-banner-main-container">
        <MapComponent />
        {mosqueData ? <MosqueDetails /> : <NearByMasjid />}
      </div>
      <Banner />
      <TrustedBy />
      <HomeInfo />
      {/* <HomeInformation/> */}
      <Features />
      <Testimonial />
      <Footer />
    </>
  );
}
export default HomePageMain;
