import React, { createContext, useState } from "react";

export const MosqueContext = createContext();

export const MosqueProvider = ({ children }) => {
  const [mosqueData, setMosqueData] = useState(null);

  return (
    <MosqueContext.Provider value={{ mosqueData, setMosqueData }}>
      {children}
    </MosqueContext.Provider>
  );
};

export default MosqueContext;
