import React, { useEffect, useState, useContext } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Marker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdMosque } from "react-icons/md";
import MosqueContext from "../../context/context"; // Import the context

const MapComponent = () => {
  const [mosques, setMosques] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [liveLocation, setLiveLocation] = useState(null);
  const [hasLocationPermission, setHasLocationPermission] = useState(true);

  const { setMosqueData } = useContext(MosqueContext); // Use the context

  useEffect(() => {
    const fetchMosques = (latitude, longitude) => {
      console.log(
        "Fetching mosques for latitude:",
        latitude,
        "longitude:",
        longitude
      );

      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      const request = {
        location: new window.google.maps.LatLng(latitude, longitude),
        radius: "5000", // 5 km radius
        type: ["mosque"],
      };

      service.nearbySearch(request, (results, status) => {
        console.log("PlacesService status:", status);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log("Fetched mosques:", results);
          setMosques(results);
        } else {
          console.error("Failed to fetch mosques:", status);
        }
        setLoading(false);
      });

      // Check if latitude or longitude is NaN
      if (isNaN(latitude) || isNaN(longitude)) {
        console.error("Invalid latitude or longitude:", latitude, longitude);
        return;
      }
    };

    if (window.google && window.google.maps && window.google.maps.places) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("current lc", latitude, longitude);

          setLiveLocation({ lat: latitude, lng: longitude });
          fetchMosques(latitude, longitude);
        },
        (error) => {
          console.error("Error getting live location:", error);
          setHasLocationPermission(false);
        }
      );
    } else {
      console.error("Google Maps API is not loaded.");
    }
  }, []);

  const fetchMosqueDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const photos = place.photos
          ? place.photos.map((photo) => photo.getUrl())
          : [];

        const address = place.formatted_address || "Address not available";

        // Ensure location is set correctly
        const location = place.geometry.location
          ? {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }
          : null;

        setMosqueData({ name: place.name, photos, address, location }); // Update the context
      } else {
        console.error("Failed to fetch mosque details:", status);
      }
    });
  };

  if (!hasLocationPermission) {
    return <div>Please enable location access to view the map.</div>;
  }

  return (
    liveLocation && (
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        onLoad={() => console.log("Maps API has loaded.")}
      >
        <div style={{ height: "100vh", width: "70%", padding: "10px" }}>
          <Map
            defaultZoom={14}
            defaultCenter={liveLocation}
            mapId="f610e2613a0a9998"
          >
            <Marker
              key="currentLocationMarker"
              position={liveLocation}
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              }}
            />
            {mosques.map((mosque) => (
              <AdvancedMarker
                key={mosque.id}
                position={{
                  lat: mosque.geometry.location.lat(),
                  lng: mosque.geometry.location.lng(),
                }}
                gmpClickable={true}
                clickable={true}
                onClick={() => {
                  setClickedLocation({
                    lat: mosque.geometry.location.lat(),
                    lng: mosque.geometry.location.lng(),
                    name: mosque.name,
                  });
                  setOpen(true);
                  fetchMosqueDetails(mosque.place_id); // Fetch details on click
                }}
              >
                <Pin
                  background={"#53CB45"}
                  glyphColor={"#000"}
                  style={{ width: "50px", height: "50px" }}
                >
                  <MdMosque style={{ width: "100%", height: "100%" }} />
                </Pin>
              </AdvancedMarker>
            ))}
            {open && clickedLocation && (
              <InfoWindow
                position={clickedLocation}
                onCloseClick={() => setOpen(false)}
              >
                <div>
                  <strong>{clickedLocation.name}</strong>
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${clickedLocation.lat},${clickedLocation.lng}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Google Maps
                  </a>
                </div>
              </InfoWindow>
            )}
          </Map>
        </div>
      </APIProvider>
    )
  );
};

export default MapComponent;

// import React, { useEffect, useState } from "react";
// import {
//   APIProvider,
//   Map,
//   AdvancedMarker,
//   Marker,
//   Pin,
//   InfoWindow,
// } from "@vis.gl/react-google-maps";
// import { FaMapMarkerAlt } from "react-icons/fa";
// import { MdMosque } from "react-icons/md";
// const url = process.env.REACT_APP_BASE_URL;

// const MapComponent = () => {
//   const [mosques, setMosques] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [clickedLocation, setClickedLocation] = useState(null);
//   const [liveLocation, setLiveLocation] = useState(null);
//   const [hasLocationPermission, setHasLocationPermission] = useState(true);

//   useEffect(() => {
//     const fetchMosques = (latitude, longitude) => {
//       console.log(
//         "Fetching mosques for latitude:",
//         latitude,
//         "longitude:",
//         longitude
//       );

//       const service = new window.google.maps.places.PlacesService(
//         document.createElement("div")
//       );

//       const request = {
//         location: new window.google.maps.LatLng(latitude, longitude),
//         radius: "5000", // 5 km radius
//         type: ["mosque"],
//       };

//       service.nearbySearch(request, (results, status) => {
//         console.log("PlacesService status:", status);
//         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//           console.log("Fetched mosques:", results);
//           setMosques(results);
//         } else {
//           console.error("Failed to fetch mosques:", status);
//         }
//         setLoading(false);
//       });

//       // Check if latitude or longitude is NaN
//       if (isNaN(latitude) || isNaN(longitude)) {
//         console.error("Invalid latitude or longitude:", latitude, longitude);
//         return;
//       }
//     };

//     // const fetchMosques = async (latitude, longitude) => {
//     //   try {
//     //     const response = await fetch(`${url}getnearbymasjeeds`, {
//     //       method: "POST",
//     //       headers: {
//     //         "Content-Type": "application/json",
//     //       },
//     //       body: JSON.stringify({
//     //         userlatitude: latitude,
//     //         userlongitude: longitude,
//     //       }),
//     //     });
//     //     if (!response.ok) {
//     //       throw new Error("Network response was not ok");
//     //     }
//     //     const data = await response.json();
//     //     setMosques(data.results);
//     //   } catch (err) {
//     //     console.error("Failed to fetch mosques:", err);
//     //   }
//     // };

//     if (window.google && window.google.maps && window.google.maps.places) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setLiveLocation({ lat: latitude, lng: longitude });
//           fetchMosques(latitude, longitude);
//         },
//         (error) => {
//           console.error("Error getting live location:", error);
//           setHasLocationPermission(false);
//         }
//       );
//     } else {
//       console.error("Google Maps API is not loaded.");
//     }
//   }, []);

//   if (!hasLocationPermission) {
//     return <div>Please enable location access to view the map.</div>;
//   }

//   return (
//     liveLocation && (
//       <APIProvider
//         apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
//         onLoad={() => console.log("Maps API has loaded.")}
//       >
//         <div style={{ height: "100vh", width: "100%", padding: "10px" }}>
//           <Map
//             defaultZoom={14}
//             defaultCenter={liveLocation}
//             mapId="f610e2613a0a9998"
//           >
//             <Marker
//               key="currentLocationMarker"
//               position={liveLocation}
//               icon={{
//                 url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png", // URL to the icon image
//               }}
//             />
//             {mosques.map((mosque) => (
//               <AdvancedMarker
//                 key={mosque.id}
//                 position={{
//                   lat: parseFloat(mosque.latitude),
//                   lng: parseFloat(mosque.longitude),
//                 }} // Ensure latitude and longitude are parsed to floats
//                 gmpClickable={true}
//                 clickable={true}
//                 onClick={() => {
//                   setClickedLocation({
//                     lat: parseFloat(mosque.latitude),
//                     lng: parseFloat(mosque.longitude),
//                   });
//                   setOpen(true);
//                 }}
//               >
//                 <Pin
//                   background={"#53CB45"}
//                   glyphColor={"#000"}
//                   style={{ width: "50px", height: "50px" }}
//                 >
//                   <MdMosque style={{ width: "100%", height: "100%" }} />
//                 </Pin>
//               </AdvancedMarker>
//             ))}
//             {open && clickedLocation && (
//               <InfoWindow
//                 position={clickedLocation}
//                 onCloseClick={() => setOpen(false)}
//               >
//                 <div>
//                   <strong>{clickedLocation.name}</strong>
//                   <a
//                     href={`https://www.google.com/maps/dir/?api=1&destination=${clickedLocation.lat},${clickedLocation.lng}`}
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     View on Google Maps
//                   </a>
//                 </div>
//               </InfoWindow>
//             )}
//           </Map>
//         </div>
//       </APIProvider>
//     )
//   );
// };

// export default MapComponent;
