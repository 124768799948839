import React from "react";
import { TiTick } from "react-icons/ti";
import "./Popup.css";

const CreatemasjidPopup = ({ closeModal }) => {
  return (
    <div className="masjid-register-popup-main-conatiner">
      <div className="masjid-popup-tick-conatiner">
        <TiTick style={{ fontSize: "30px", color: "#04c90e" }} />
      </div>
      <p className="masjid-popup-response">We have received your request!</p>
      <p className="masjid-popup-contact">
        Our Team will approve your Masjid registration with in 24 hours.
      </p>
      <button onClick={closeModal} className="masjid-popup-done-button">
        Done
      </button>
    </div>
  );
};

export default CreatemasjidPopup;
