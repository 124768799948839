import "./index.css";

function Banner() {
  return (
    <div className="banner-main-container">
      <div className="banner-sub-container">
        <a
          href="https://play.google.com/store/apps/details?id=com.lgs.merimasjid"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://my-masjid.com/wp-content/uploads/2020/12/google_playicon-300x76-1.png"
            alt=""
            className="banner-android-app-image"
          />
        </a>
        <p className="app-para-text" style={{ color: "#fff" }}>
          Download Meri Masjid for accurate locations and prayer timings for
          masjids around you. Stay connected to your faith wherever you go.
        </p>
      </div>
    </div>
  );
}
export default Banner;
