import * as React from "react";
import "./index.css";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../Loader";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Clock from "../Home/Clock";
import Toast from "../utils/Toast";
import {
  selectedGridRowsSelector,
  gridFilteredSortedRowIdsSelector,
} from "@mui/x-data-grid";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

function SingleMasjidTime() {
  const { id } = useParams();

  const [masjidTimingList, setMasjidTimingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(new Date());
  const [showBanner, setShowBanner] = useState(false);
  const [names, setNames] = useState([]);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [sehar, setSehar] = useState("");
  const [iftar, setIftar] = useState("");
  const [masjiddetails, setMasjidDetails] = useState({});
  const [todayDate, setTodayDate] = useState("");

  const url = process.env.REACT_APP_BASE_URL;

  const fetchData = async () => {
    const token = Cookies.get("adminuser");
    setLoading(true);

    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const api = `${url}getTodaySchedule/${id}`;
    try {
      const response = await fetch(api, options);

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      setLoading(false);

      const data = await response.json();
      Toast.fire({
        icon: "success",
        title: data.message,
      });

      setMasjidDetails(data.results);
      const timings = data.results;
      const timingList = [
        { name: "Fajr", starttime: timings.fajr, endtime: timings.fajriqamah },
        {
          name: "Dhuhr",
          starttime: timings.dhuhr,
          endtime: timings.dhuhriqamah,
        },
        { name: "Asr", starttime: timings.asr, endtime: timings.asriqamah },
        {
          name: "Maghrib",
          starttime: timings.maghrib,
          endtime: timings.maghribiqamah,
        },
        { name: "Isha", starttime: timings.isha, endtime: timings.ishaiqamah },
        {
          name: "Jumah",
          starttime: timings.jumah,
          endtime: timings.jumahadhan,
        },
      ];
      setMasjidTimingList(timingList);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Salah",
      width: 250,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
    },
    {
      field: "starttime",
      headerName: "Adhan",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      width: 250,
      align: "center",
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "endtime",
      headerName: "Iqamah",
      headerClassName: "super-app-theme--header",
      width: 250,
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
    },
  ];

  const getRowId = (row) => row.name;

  const getRowClassName = (params) => {
    return params.row.id % 2 === 1 ? "even-row" : "odd-row";
  };

  useEffect(() => {
    if (masjidTimingList && masjidTimingList.length > 0) {
      const updatedNames = masjidTimingList.map(({ name, starttime }) => {
        const [hours, minutes] = starttime.split(":");
        return {
          text: name,
          dynamicHours: parseInt(hours, 10),
          dynamicMinutes: parseInt(minutes, 10),
        };
      });
      setNames(updatedNames);
    }
  }, [masjidTimingList]);

  useEffect(() => {
    const currentMinutes = time.getHours() * 60 + time.getMinutes();
    const nextPrayerIndex = names.findIndex(
      ({ dynamicHours, dynamicMinutes }) =>
        currentMinutes < dynamicHours * 60 + dynamicMinutes
    );

    if (nextPrayerIndex !== -1) {
      const nextPrayerTime = names[nextPrayerIndex];
      const remaining =
        nextPrayerTime.dynamicHours * 60 +
        nextPrayerTime.dynamicMinutes -
        currentMinutes;

      setRemainingMinutes(remaining);

      if (remaining > 0 && remaining < 4) {
        setShowBanner(true);
        const bannerTimeout = setTimeout(() => {
          setShowBanner(false);
        }, 1000);

        return () => clearTimeout(bannerTimeout);
      }
    }

    setShowBanner(false);
  }, [time, names]);

  const hour = time.getHours();
  const minute = time.getMinutes();
  const second = time.getSeconds();

  const handleShowBannerChange = (value) => {
    setShowBanner(value);
  };

  useEffect(() => {
    const date = new Date();
    const TodayDateandYear = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    setTodayDate(TodayDateandYear);
  });

  const fetchRamzanData = async () => {
    const options = {
      method: "GET",
    };
    const api = `${url}getramzantimings`;
    try {
      const response = await fetch(api, options);

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const data = await response.json();
      Toast.fire({
        icon: "success",
        title: data.message,
      });

      const masjeedData = data.data[0];

      const seharTime = masjeedData.sehar.split(":").slice(0, 2).join(":");
      const iftarTime = masjeedData.iftar.split(":").slice(0, 2).join(":");

      setSehar(seharTime);
      setIftar(iftarTime);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchRamzanData();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="select-masjid-warning-container">
          <div className="select-masjid-time-flex-container">
            <Clock
              masjidTimingList={masjidTimingList}
              masjeedDetails={masjiddetails}
              onShowBannerChange={handleShowBannerChange}
            />
            <div className="single-masjid-list-main-container">
              {masjidTimingList.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    "& .super-app-theme--header": {
                      backgroundColor: "#194373",
                      color: "#fff",
                      fontSize: "30px",
                    },
                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none !important",
                        border: "none !important",
                        boxShadow: "none !important",
                      },
                    "& .even-row, & .odd-row": {
                      backgroundColor: "#fff",
                      fontSize: "24px",
                    },
                    "& .even-row:hover, & .odd-row:hover": {
                      backgroundColor: "#f2f2f2",
                      cursor: "pointer",
                    },
                  }}
                >
                  <DataGrid
                    rows={masjidTimingList}
                    columns={columns}
                    getRowId={getRowId}
                    getRowClassName={getRowClassName}
                    pageSizeOptions={[5, 10, 15, 20, 100]}
                    disableSelectionOnClick
                    selectionModel={{}}
                    disableRowSelectionOnClick
                    slots={{
                      printOptions: {
                        getRowsToExport: getSelectedRowsToExport,
                        hideFooter: true,
                        hideToolbar: true,
                        includeCheckboxes: true,
                      },
                    }}
                  />
                </Box>
              )}
            </div>
            <div className="ramzan-timings-container">
              {sehar && (
                <h1 className="ramzan-sehar-time">Sehar : {sehar} AM</h1>
              )}
              {iftar && (
                <h1 className="ramzan-iftar-time">Iftar : {iftar} PM</h1>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SingleMasjidTime;
