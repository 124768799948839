import React, { useEffect, useState } from "react";
import "./index.css";
import Navbar from "../Navbar";
import Toast from "../utils/Toast";
import ExcelDownloadLink from "../ExcelDownload";

const MasidRegisterMoblie = () => {
  const [formData, setFormData] = useState({
    masjeedname: "",
    adminname: "",
    email: "",
    phonenumber: "",
    country: "India",
    state: "Telangana",
    city: "",
    postalcode: "",
    address: "",
    latitude: "",
    longitude: "",
    inMasjid: false,
    fajr: "05:00",
    dhuhr: "12:00",
    asr: "15:00",
    maghrib: "18:00",
    isha: "20:00",
    jumah: "12:00",
  });

  const [cities, setCities] = useState([]);

  const url = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    const file = type === "file" ? files[0] : null;
    const fieldValue =
      type === "file" ? file : type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));

    if (name === "inMasjid" && checked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setFormData((prevData) => ({
              ...prevData,
              latitude: latitude.toString(),
              longitude: longitude.toString(),
            }));
          },
          (error) => {
            console.error("Error getting location:", error);
            Toast.fire({
              icon: "error",
              title: "Could not retrieve your location",
            });
          }
        );
      } else {
        Toast.fire({
          icon: "error",
          title: "Geolocation is not supported by this browser",
        });
      }
    } else if (name === "inMasjid" && !checked) {
      setFormData((prevData) => ({
        ...prevData,
        latitude: "",
        longitude: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "hello");
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });

    try {
      const response = await fetch(`${url}addmasjeed`, {
        method: "POST",
        body: form,
      });

      if (response.ok) {
        setFormData({
          masjeedname: "",
          adminname: "",
          email: "",
          phonenumber: "",
          country: "India",
          state: "Telangana",
          city: "",
          postalcode: "",
          address: "",
          latitude: "",
          longitude: "",
          inMasjid: false,
          fajr: "05:00",
          dhuhr: "12:00",
          asr: "15:00",
          maghrib: "18:00",
          isha: "20:00",
          jumah: "12:00",
        });
        setCities([]);
        fetchCities("TG");
        const data = await response.json();
        console.log(data);
        Toast.fire({
          icon: "success",
          title: data.message,
        });
      } else {
        const data = await response.json();
        Toast.fire({
          icon: "error",
          title: data.message,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const fetchCities = async () => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "bjNPeE1FZEZYRnhsUmo5ZzBRTWRPWHBaRGEzRWl4MWVKbmxWalYxTQ=="
    ); // Replace YOUR_API_KEY with your actual API key

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/IN/states/TG/cities`,
        requestOptions
      );
      const data = await response.json();
      setCities(data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    const name = e.target.name;
    const value = e.target.value;
    if (selectedCityId) {
      setFormData({ ...formData, [name]: value });
    } else {
      setCities([]);
    }
  };

  useEffect(() => {
    fetchCities("TG");
  }, []);

  return (
    <>
      <div className="masid-form-main-container">
        <form className="masid-form-container" onSubmit={handleSubmit}>
          <h1 className="app-para-heading" style={{ fontSize: "24px" }}>
            Create Masjid
          </h1>
          <p className="app-para-text">
            Are you a owner of the Masjid? Provide your Masjid details below and
            register with us for FREE.
          </p>
          <div className="masid-form-field">
            <label className="app-para-text">Masjid Name *</label>
            <input
              type="text"
              name="masjeedname"
              value={formData.masjeedname}
              onChange={handleChange}
              className="masid-form-input"
              required
            />
          </div>
          <div className="masid-form-field">
            <label className="app-para-text">Admin Name *</label>
            <input
              type="text"
              name="adminname"
              value={formData.adminname}
              onChange={handleChange}
              className="masid-form-input"
              required
            />
          </div>

          <div className="masid-form-field">
            <label className="app-para-text">Email </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="masid-form-input"
            />
          </div>

          <div className="masid-form-field">
            <label className="app-para-text">Phone Number *</label>
            <input
              type="tel"
              name="phonenumber"
              value={formData.phonenumber}
              onChange={handleChange}
              className="masid-form-input"
              required
            />
          </div>

          <div className="masid-form-field">
            <label className="app-para-text">City *</label>
            <select
              onChange={handleCityChange}
              className="masid-form-input"
              name="city"
              required
            >
              <option value="">Select City</option>
              {cities.map((city) => (
                <option key={city.id} value={city.iso2}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div className="masid-form-field">
            <label className="app-para-text">Postal code *</label>
            <input
              type="text"
              name="postalcode"
              value={formData.postalcode}
              onChange={handleChange}
              className="masid-form-input"
              required
            />
          </div>

          <div className="masid-form-field">
            <label className="app-para-text">Street Address *</label>
            <textarea
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="masid-form-input"
              required
            />
          </div>
          <div className="masid-form-field-container">
            <div className="masid-form-field masjid-form-time-container">
              <label className="app-para-text">Fajr *</label>
              <input
                type="time"
                name="fajr"
                value={formData.fajr}
                onChange={handleChange}
                className="masid-form-input"
                required
              />
            </div>
          </div>
          <div className="masid-form-field-container">
            <div className="masid-form-field masjid-form-time-container">
              <label className="app-para-text">Dhuhr *</label>
              <input
                type="time"
                name="dhuhr"
                value={formData.dhuhr}
                onChange={handleChange}
                className="masid-form-input"
                required
              />
            </div>
          </div>
          <div className="masid-form-field-container">
            <div className="masid-form-field masjid-form-time-container">
              <label className="app-para-text">Asr *</label>
              <input
                type="time"
                name="asr"
                value={formData.asr}
                onChange={handleChange}
                className="masid-form-input"
                required
              />
            </div>
          </div>
          <div className="masid-form-field-container">
            <div className="masid-form-field masjid-form-time-container">
              <label className="app-para-text">Maghrib *</label>
              <input
                type="time"
                name="maghrib"
                value={formData.maghrib}
                onChange={handleChange}
                className="masid-form-input "
                required
              />
            </div>
          </div>
          <div className="masid-form-field-container">
            <div className="masid-form-field masjid-form-time-container">
              <label className="app-para-text">Isha *</label>
              <input
                type="time"
                name="isha"
                value={formData.isha}
                onChange={handleChange}
                className="masid-form-input"
                required
              />
            </div>
          </div>
          <div className="masid-form-field-container">
            <div className="masid-form-field masjid-form-time-container">
              <label className="app-para-text">Jumah *</label>
              <input
                type="time"
                name="jumah"
                value={formData.jumah}
                onChange={handleChange}
                className="masid-form-input"
                required
              />
            </div>
          </div>
          <div className="masid-form-field-checkbox-container">
            <input
              type="checkbox"
              name="inMasjid"
              checked={formData.inMasjid}
              onChange={handleChange}
              className="masid-form-checkbox"
            />
            <label className="app-para-heading">Are you in the Masjid?</label>
          </div>
          <div className="masjid-register-button-container">
            <button
              className="app-container-button"
              type="submit"
              style={{ width: "140px" }}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MasidRegisterMoblie;
