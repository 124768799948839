import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import { useEffect, useState } from "react";
import SingleMasjidTime from "../SingleMasjidTime";
import NotFound from "../NotFound";
import Navbar from "../Navbar";
import backgroundImg from "../../components/utils/ms1.png";
import { FaClock } from "react-icons/fa";
import { MdDirections } from "react-icons/md";

function SelectMasjid() {
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedMasjid, setSelectedMasjid] = useState("");
  const [masjidTodayTimings, setMasjidTodayTimings] = useState();

  const [cities, setCities] = useState([]);
  const [masjids, setMasjids] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const url = process.env.REACT_APP_BASE_URL;
  const country = "India";
  const state = "Telangana";

  useEffect(() => {
    fetchCities();
    getUserLocation();
  }, []);

  const fetchCities = async () => {
    const api = `${url}getCities?country=${country}&state=${state}`;
    console.log(api);
    const options = {
      method: "GET",
    };

    try {
      const response = await fetch(api, options);
      const data = await response.json();
      setCities(data.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const selectCity = async (city) => {
    setSelectedCity(city);

    const api = `${url}getwebmasjeeds?country=${country}&state=${state}&city=${city}&userlatitude=${userLocation.latitude}&userlongitude=${userLocation.longitude}`;
    const options = {
      method: "GET",
    };

    try {
      const response = await fetch(api, options);
      const data = await response.json();
      console.log(data, "helloworld");
      setMasjids(data.data);
    } catch (error) {
      console.error("Error fetching masjids:", error);
    }
  };

  const navigate = useNavigate();

  const selectMasjid = async (id) => {
    setSelectedMasjid(id);
    navigate(`/singlemasjid/${id}`);

    const api = `${url}getTodaySchedule/${id}`;
    const options = {
      method: "GET",
    };

    try {
      const response = await fetch(api, options);
      const data = await response.json();
      setMasjidTodayTimings(data.data);
    } catch (error) {
      console.error("Error fetching today's schedule:", error);
    }
  };

  const handleViewTimings = (mosqueId) => {
    navigate(`/singlemasjid/${mosqueId}`);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = cities.filter((city) =>
      city.city.toLowerCase().includes(query)
    );
    setCities(filtered);
  };

  return (
    <>
      <Navbar />
      <div className="select-masjid-main-container">
        <div className="select-masjid-sub-image-container">
          <img
            src={backgroundImg}
            alt=""
            className="select-masjid-sub-image-container-image"
          />
        </div>
        {cities ? (
          <div className="select-masjid-sub-container">
            <input
              type="search"
              className="select-masjid-seach-input"
              placeholder="Search by city"
              value={searchQuery}
              onChange={handleSearch}
            />
            {selectedCity === "" && (
              <ul className="select-masjid-list-container">
                <li className="app-para-text">Select City</li>
                {cities.map((each) => (
                  <li
                    className="app-para-text select-masjid-category-hover"
                    key={each.city}
                    onClick={() => selectCity(each.city)}
                  >
                    {each.city}
                  </li>
                ))}
              </ul>
            )}
            {selectedCity && selectedMasjid === "" && (
              <ul className="select-masjid-list-container-masjid">
                <li className="app-para-text">Select Masjid</li>
                {masjids.map((each) => (
                  <li
                    className="app-para-text "
                    key={each.masjeedname}
                    onClick={() => selectMasjid(each.id)}
                  >
                    <div
                      className="masjid-card"
                      key={each.id}
                      style={{ borderBottom: "2px solid #E4EDF1F0" }}
                    >
                      <div className="masjid-details">
                        <h2 className="masjid-details-title">
                          {each.masjeedname}
                        </h2>
                        <p className="masjid-details-distance">
                          {each.distance} km
                        </p>
                        <p className="masjid-details-address">
                          Address: {each.address}
                        </p>
                        <p className="masjid-details-address">
                          {each.city}, {each.state}, {each.postalcode}
                        </p>
                        <div className="masjid-actions">
                          <button
                            className="timings-button"
                            onClick={() => handleViewTimings(each.id)}
                          >
                            <FaClock />
                            Timings
                          </button>
                          <button
                            className="directions-button"
                            onClick={() =>
                              window.open(
                                `https://www.google.com/maps/dir/?api=1&destination=${each.latitude},${each.longitude}`,
                                "_blank"
                              )
                            }
                          >
                            <MdDirections />
                            Direction
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
}

export default SelectMasjid;
